import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import { CaricoMerceContext } from "../contexts";
import { useContext, useState } from "react";
import { Search } from "@mui/icons-material";
import { AuthContext } from '../contexts';
import { TrazioneTable } from "../components";
import {  
  IconButton,
  InputAdornment
} from "@mui/material";

const MagPartenzaTrazione = () => {
  let navigate = useNavigate();
  const inputRef = React.useRef();

  
  const {
    state:{
      visibleMenu,
      group
    },
    login
  } = useContext(AuthContext);

 /* const {
    state:{
      visibleMenu,
      group
    }
  } = useContext(AuthContext);*/

  const { 
    state:{
      RicercaViaggi,
      RicercaColumns,
    },
    getPartenzaTrazione,
    getRicercaPartenzaTrazione,
    getPartenzaPerTrazioneViaggio
   } = useContext(CaricoMerceContext);

  const validations = [
    ({ code }) => isRequired(code) || { code: "*Barcode obbligatorio" },
  ];

  React.useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    getRicercaPartenzaTrazione();

  }, []);

  const {
    values: barcodeForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      code: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      inputRef.current.blur();
      if (barcodeForm.code && isValid) {
        getPartenzaTrazione(barcodeForm.code, navigate);
      }
    }
  };

 

  return (
    <>
      <Stack direction={"row"} spacing={2} m={2}>
        <TextField
          id="outlined-basic"
          label="Barcode viaggio"
          variant="outlined"
          placeholder="Inserire il barcode"
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          /* onClick={()=> inputRef.current.blur()}
              onDoubleClick={()=> inputRef.current.focus()} */
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          onChange={(e) => changeHandler("code", e.target.value)}
          error={Boolean(touched.code && errors.code)}
          //helperText={touched.code && errors.code}
          value={barcodeForm.code || ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" disabled={!isValid}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* {partTrazRicercaColumns.length !== 0?
      (<Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <DataGrid
            autoHeight
            rows={partTrazRicercaViaggi}
            columns={partTrazRicercaColumns}
            getRowId={(row) => row.PrefissoNumeroViaggio}
          />
        </Grid>
      </Grid>  )
      :(<></>)
      } */}

       {/* <CustomPaginationActionsTable
        //setCheckBoxSelectionPrint={setCheckBoxSelectionPrint}
        rows={partTrazRicercaViaggi}
        columns={partTrazRicercaColumns}
        limit={limit}
        recordCount={partTrazCount}
        idElement={"PrefissoNumeroViaggio"}
        actionShow={true}
        //routeDetail={"/documenti"}
        //resetFiltri={resetFiltri}
        pageReset={pageResetVar}
        viewAction={{
          mail: false,
          view: false,
          location: false,
          download: true,
          print: false,
        }}
      />  */}

      <TrazioneTable columnsData={RicercaColumns} rowsData={RicercaViaggi} navigate={navigate} detailFunc={getPartenzaPerTrazioneViaggio}/>
    </>
  );
};
export default MagPartenzaTrazione