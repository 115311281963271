import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import { CaricoMerceContext } from "../contexts";
import { useContext } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { TrazioneTable } from "../components";


const MagArrivoTrazione = () => {
  let navigate = useNavigate();
  const inputRef = React.useRef();
  
  const {
    state:{
      RicercaColumns,
      RicercaViaggi,
    },
    getArrivoTrazione,
    getRicercaArrivoTrazione, 
    getArrivoDaTrazioneViaggio,
    resetRicercaData,

  } = useContext(CaricoMerceContext);

  const validations = [
    ({ code }) => isRequired(code) || { code: "*Barcode obbligatorio" },
  ];

  React.useEffect(() => {
    resetRicercaData();

    setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    getRicercaArrivoTrazione();
  }, []);

  const {
    values: barcodeForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      code: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      inputRef.current.blur();
      if (barcodeForm.code && isValid) {
        getArrivoTrazione(barcodeForm.code, navigate);
      }
    }
  };

  return (
    <>
      <Stack direction={"row"} spacing={2} m={2}>
        <TextField
          id="outlined-basic"
          label="Barcode viaggio"
          variant="outlined"
          placeholder="Inserire il barcode"
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}

          onKeyPress={(e) => onkeyEnter(e.charCode)}
          onChange={(e) => changeHandler("code", e.target.value)}
          error={Boolean(touched.code && errors.code)}

          value={barcodeForm.code || ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="primary" disabled={!isValid}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <TrazioneTable columnsData={RicercaColumns} rowsData={RicercaViaggi} navigate={navigate} detailFunc={getArrivoDaTrazioneViaggio}/>



    </>
  );
};
export default MagArrivoTrazione