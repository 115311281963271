import React, { useContext } from 'react'

import {  
    Table,
    TableCell,
    TableBody,
    TableRow,
    TableContainer,
    TableHead,
    Button,

  } from "@mui/material";

import { CaricoMerceContext } from '../contexts';

const TrazioneTable = ({columnsData, rowsData, navigate, detailFunc}) => {
    const {
        resetRicercaData,
    } = useContext(CaricoMerceContext)
    
    const onVisualizza = (prgViaggio, navigate) => {
        resetRicercaData();
        detailFunc(prgViaggio, navigate)
    }

    function getButtonCell(value, prgViaggio, navigate){
        return(
            <>
                    <TableCell align="center" >
                        <Button onClick={() => {onVisualizza(prgViaggio, navigate)}}>
                            {value}
                        </Button>
                    </TableCell>
            </>
        );
    }

  return (
    <TableContainer>
        {/* <strong >asd</strong> */}
        <Table >
        <TableHead>
            
            <TableRow>
            {
                columnsData.map((col) => (
                <TableCell align="center" key={col.id}>
                    {col.label}
                </TableCell>
                ))
            }   

            </TableRow>
        </TableHead>
        <TableBody>
            {
            rowsData.map((row) => (
                    <TableRow key={row.PrgViaggio}>
                        {/* <TableCell align="center" >
                            {row.StatoDiCarico}
                        </TableCell>
                        <TableCell align="center">
                            {row.PrefissoNumeroViaggio}
                        </TableCell>
                        <TableCell align="center">
                            {row.AutistaNome}
                        </TableCell>
                        <TableCell align="center">
                            {row.Targa}
                        </TableCell>
                        <TableCell align="center">
                            {row.FornitoreRagSoc}
                        </TableCell >
                        <TableCell align="center">
                            {row.TermPartenza}
                        </TableCell>
                        <TableCell align="center">
                            {row.TermArrivo}
                        </TableCell>
                        <TableCell align="center">
                            {row.CorrispondenteRagSoc}
                        </TableCell>
                        <TableCell align="center">
                            {row.NConsegne}
                        </TableCell> */}
                        {getButtonCell(row.StatoDiCarico, row.PrgViaggio,navigate)}
                        {getButtonCell(row.PrefissoNumeroViaggio, row.PrgViaggio,navigate)}
                        {getButtonCell(row.AutistaNome, row.PrgViaggio,navigate)}
                        {getButtonCell(row.Targa, row.PrgViaggio,navigate)}
                        {getButtonCell(row.FornitoreRagSoc, row.PrgViaggio,navigate)}
                        {getButtonCell(row.TermPartenza, row.PrgViaggio,navigate)}
                        {getButtonCell(row.TermArrivo, row.PrgViaggio,navigate)}
                        {getButtonCell(row.CorrispondenteRagSoc, row.PrgViaggio,navigate)}
                        {getButtonCell(row.NConsegne, row.PrgViaggio,navigate)}

                    </TableRow> 

            ))
            }

        </TableBody>
        </Table>
    </TableContainer>
  )
}

export default TrazioneTable