import {
  Send,
} from "@mui/icons-material";
import {
  Alert,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CaricoMerceContext } from "../contexts";
import { useForm } from "../hooks";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TableRowHtml } from "../components";
import ResponsiveDialog from "../components/ResponsiveDialog";

const MagPartenzaTrazioneDettaglio = (props) => {
  const navigate = useNavigate()
  const { idBarcodeViaggio } = useParams();
  const [customCol, setCustomCol] = React.useState([]);
  const [modalApprove, setModalApprove] = React.useState(false);
  const inputRef = React.useRef();

  const {
    state: { columns, viaggio, infoViaggio, messageDialog },
    getPartenzaPerTrazioneData,
    postPartenzaPerTrazione,
  } = useContext(CaricoMerceContext);

  useEffect(() => {
    if (idBarcodeViaggio) {
      getPartenzaPerTrazioneData(idBarcodeViaggio, navigate);
    }
  }, []);

  const validations = [];
  const {
    values: posizioneForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
    },
    validations
  );

  React.useEffect(() => {
    let colonneDaModificare = [];
    columns.map((res, i) => {
      colonneDaModificare = [
        ...colonneDaModificare,
        {
          field: res.id,
          headerName: res.label,
          minWidth: res.minWidth,
        },
      ];
    });
    setCustomCol(colonneDaModificare);
  }, [columns]);

  const onkeyEnter = (event) => {
    if (event === 13) {
      postPartenzaPerTrazione(
        idBarcodeViaggio,
        idBarcodeViaggio,
        posizioneForm.RiferimPaletta,
        setModalApprove
      );
      setValues({
        RiferimPaletta: "",
      });
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  };

  const saveChange = () => {
    postPartenzaPerTrazione(
      idBarcodeViaggio,
      idBarcodeViaggio,
      posizioneForm.RiferimPaletta,
      setModalApprove
    );
    setValues({
      RiferimPaletta: "",
    });
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  return (
    <Grid container>
      <ResponsiveDialog
        openModal={modalApprove}
        setModalApprove={setModalApprove}
        messageDialog={messageDialog}
      />

      <Grid
        container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <TextField
          id="outlined-basic"
          label="Segnacollo"
          variant="outlined"
          placeholder="Inserire il segnacollo"
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
          value={posizioneForm.RiferimPaletta || ""}
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={() => saveChange()}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid container mt={8}>
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info" component="span">
            {
              <ReactMarkdown
                children={
                  infoViaggio?.displaytext
                    ? infoViaggio.displaytext.toString()
                    : " "
                }
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
          <Alert severity="warning" component="span">
            {
              <ReactMarkdown
                children={
                  infoViaggio?.summarytext
                    ? infoViaggio.summarytext.toString()
                    : " "
                }
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }
          </Alert>
          <Grid item xs={12} md={12} lg={12}>
            {customCol?.length > 0 ? (
              <TableRowHtml customCol={customCol} rows={viaggio} />
            ) : (
              <></>
            )}
          </Grid>

        </Grid>
        
      </Grid>
        {/* <Button variant="contained" size="large" sx={{mt:5}}>
          Chiusura carico
        </Button> */}
    </Grid>
  );
};

export default MagPartenzaTrazioneDettaglio;
